import React, { useState, createContext, useContext } from "react";

export const BannerContext = createContext();

export const BannerContextProvider = props => {
    const [bannerHeight, setBannerHeight] = useState(0)

  return (
    <BannerContext.Provider value={{bannerHeight, setBannerHeight}}>
      {props.children}
    </BannerContext.Provider>
  );
}

export const useBannerContext = () => {
    return useContext(BannerContext)
  }
  