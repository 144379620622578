import React from "react"
import { Provider } from "react-redux"
import rootReducer from "./reducers/index"
import { BannerContextProvider } from "../context/BannerContext"
import { BlackFridayContextProvider } from "../context/BlackFridayContext"
import { configureStore } from '@reduxjs/toolkit'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // This is to disable the warning about non-serializable values
    }),
  // Add other configuration options as needed
})

const ReduxWrapperProvider = ({element}) => (
  <Provider store={store}>
    <BlackFridayContextProvider>
      <BannerContextProvider>
        {element}
      </BannerContextProvider>
    </BlackFridayContextProvider>
  </Provider>
);

export default ReduxWrapperProvider;
