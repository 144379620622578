import UserActionTypes from "../actionTypes/user"

const initialState = {
  userDetails : null,
  userOrders: null,
  referral: null
};

const UserReducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case UserActionTypes.ADD_USER_DETAILS_ACTION:
      return {
        ...state,
        userDetails: payload.userDetails
      };
      case UserActionTypes.ADD_USER_ORDERS_ACTION:
      return {
        ...state,
        userOrders: payload.userOrders
      };
      case UserActionTypes.RESET_USER_ACTION:
      return {
        ...state,
        userOrders: null,
        userDetails: null,
        referral: null
      };
      case UserActionTypes.ADD_REFERRAL_ACTION:
      return {
        ...state,
        referral: payload.referral
      };
    default:
      return state;
  }
};

export default UserReducer;

export function getUser(store) {
    return store
}

export function getUserDetails(store) {
    return store.userDetails
}

export function getReferral(store) {
  return store.referral
}

export function getUserOrders(store) {
    return store.userOrders
}
