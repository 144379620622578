import React, { createContext, useContext, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const BlackFridayContext = createContext()

export const BlackFridayContextProvider = (props) => {
  const { home } = useStaticQuery(
    graphql`
      query {
        home: contentfulHome {
          blackFriday
        }
      }
    `
  )
  
  const [isBlackFriday] = useState(home.blackFriday)

  return (
    <BlackFridayContext.Provider value={{isBlackFriday}}>
      {props.children}
    </BlackFridayContext.Provider>
  )
}

export const useBlackFridayContext = () => {
  return useContext(BlackFridayContext)
}
