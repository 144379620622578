import CartActionTypes from "../actionTypes/cart"

const initialState = {
  client: null,
  checkout: null,
  checkoutUrl: null,
  items: [],
  subTotal: null,
  estimatedShipping: null,
  estimatedTax: null,
  taxesIncluded: null,
  totalPrice: null,
  currencyCode: null,
  couponCode: null,
  isLoading: false,
  isAdded: false,
  removing: [],
  removed: [],
  isToggleBasket: false,
};

const CartReducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case CartActionTypes.INIT_ACTION:
      return {
        ...state,
        client: payload.client,
        checkout: payload.checkout,
        removing: [],
        removed: []
      };
    case CartActionTypes.ADDING_ACTION:
    case CartActionTypes.DISCOUNT_ADDING_ACTION:
    case CartActionTypes.DISCOUNT_REMOVING_ACTION:
    case CartActionTypes.UPDATING_ATTRIBUTES_ACTION:
    case CartActionTypes.UPDATING_ACTION:
      return {
        ...state,
        isLoading: true
      };
    case CartActionTypes.REMOVING_ACTION:
      return {
        ...state,
        removing: payload.removing
      };
    case CartActionTypes.ADD_ACTION:
    case CartActionTypes.REMOVE_ACTION:
    case CartActionTypes.DISCOUNT_ADD_ACTION:
    case CartActionTypes.DISCOUNT_REMOVE_ACTION:
    case CartActionTypes.UPDATE_ACTION:
      return {
        ...state,
        isLoading: false,
        checkout: payload.checkout,
        checkoutUrl: payload.checkoutUrl,
        items: payload.items,
        subTotal: payload.subTotal,
        estimatedShipping: payload.estimatedShipping,
        totalPrice: payload.totalPrice,
        isAdded: payload.isAdded,
        couponCode: payload.couponCode,
        isToggleBasket: payload.isToggleBasket,
      };
    case CartActionTypes.ADDED_ACTION:
      return {
        ...state,
        isAdded: payload.isAdded,
        isToggleBasket: payload.isToggleBasket
      };
    case CartActionTypes.REMOVED_ACTION:
      return {
        ...state,
        removed: payload.removed
      };
    case CartActionTypes.UPDATE_ATTRIBUTES_ACTION:
      return {
        ...state,
        isLoading: false,
        client: payload.client,
        checkout: payload.checkout,
        isAdded: false
      };
      case CartActionTypes.UPDATED_ACTION:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
};

export default CartReducer;

export function getCart(store) {
  return store
}

export function isLoading(store) {
  return store.isLoading
}

export function isAdded(store) {
  return store.isAdded
}

export function isToggleBasket(store) {
  return store.isToggleBasket
}

export function getItemsCount(store) {
  let count = 0;

  if (store.checkout) {
    let lineItems = store.checkout.lineItems;

    lineItems.forEach((lineItem) => {
      count += lineItem.quantity;
    });
  }

  return count;
}

export function getCheckout(store) {
  return {
    items: store.items,
    checkoutUrl: store.checkoutUrl,
    couponCode: store.couponCode,
    isAdded: store.isAdded
  }
}

export function getRemoving(store) {
  return store.removing;
}

export function getRemoved(store) {
  return store.removed;
}
