const CartActionTypes = {
  INIT_ACTION: 'INIT_ACTION',
  ADD_ACTION: 'ADD_ACTION',
  ADDING_ACTION: 'ADDING_ACTION',
  ADDED_ACTION: 'ADDED_ACTION',
  REMOVE_ACTION: 'REMOVE_ACTION',
  REMOVING_ACTION: 'REMOVING_ACTION',
  REMOVED_ACTION: 'REMOVED_ACTION',
  DISCOUNT_ADD_ACTION: 'DISCOUNT_ADD_ACTION',
  DISCOUNT_ADDING_ACTION: 'DISCOUNT_ADDING_ACTION',
  UPDATE_ATTRIBUTES_ACTION: 'UPDATE_ATTRIBUTES_ACTION',
  UPDATING_ATTRIBUTES_ACTION: 'UPDATING_ATTRIBUTES_ACTION',
  UPDATING_ACTION: 'UPDATING_ACTION',
  UPDATE_ACTION: 'UPDATE_ACTION',
  UPDATED_ACTION: 'UPDATED_ACTION',
  DISCOUNT_REMOVE_ACTION: 'DISCOUNT_REMOVE_ACTION',
  DISCOUNT_REMOVING_ACTION: 'DISCOUNT_REMOVING_ACTION',
};

export default CartActionTypes;
