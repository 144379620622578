import { combineReducers } from "redux"
import cart, * as CartSelectors from "./cart"
import user, * as UserSelectors from "./user"

const CART = 'CART';
const USER = 'USER';

const reducers = {
  [CART]: cart,
  [USER]: user,
};

export default combineReducers(reducers)

export function isLoading(store) {
  return CartSelectors.isLoading(store[CART])
}

export function isAdded(store) {
  return CartSelectors.isAdded(store[CART])
}

export function isToggleBasket(store) {
  return CartSelectors.isToggleBasket(store[CART])
}

export function getItemsCount (store) {
  return CartSelectors.getItemsCount(store[CART]);
}

export function getCart(store) {
  return CartSelectors.getCart(store[CART])
}

export function getCheckout(store) {
  return CartSelectors.getCheckout(store[CART])
}

export function getRemoving (store) {
  return CartSelectors.getRemoving(store[CART]);
}

export function getRemoved (store) {
  return CartSelectors.getRemoved(store[CART]);
}

export function getUserDetails (store) {
  return UserSelectors.getUserDetails(store[USER])
}

export function getUserOrders (store) {
  return UserSelectors.getUserOrders(store[USER])
}

export function getReferral (store) {
  return UserSelectors.getReferral(store[USER])
}
